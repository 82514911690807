
import { ref, PropType, watch, toRefs } from 'vue';
import dayjs from 'dayjs';

const formatTime = (time: string) => {
  if (!time) return;

  const TIME_FORMAT = 'YYYY-MM-DD';
  return dayjs(time).format(TIME_FORMAT);
};

export default {
  props: {
    /**
     * search btn 是否disabled
     */
    disable: {
      type: Boolean,
      default: false
    },
    /**
     * 限制天數
     */
    limitDayCount: {
      type: Number,
      default: 0
    },
    /**
     * callback for search btn
     */
    test: {
      type: Number,
      default: 0
    },
    /**
     * 日期區間
     */
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { dateRange } = toRefs(props);
    const { limitDayCount } = toRefs(props);

    /**
     * 日期 limit
     */
    const dateValues = ref([...dateRange.value]);
    watch(dateRange, (dateRange: string[]) => {
      dateValues.value = [...dateRange];
    });

    const clearEndDate = () => {
      if (dateValues.value[1]) dateValues.value[1] = null;
    };

    const dateLimit = (datesOnCalendar: any): Boolean => {
      if (limitDayCount.value <= 0) {
        return false;
      }
      const startDate = dateValues.value[0];
      const datesTimestamp = datesOnCalendar.getTime();
      const startDateTimestamp = dayjs(startDate).valueOf();

      if (startDate && limitDayCount.value > 0) {
        const availableDuration = dayjs(startDate).add(limitDayCount.value - 1, 'day');
        const availableDates = dayjs(availableDuration).valueOf();
        const beforeStartDate = datesTimestamp < startDateTimestamp;

        return datesTimestamp > availableDates || beforeStartDate;
      } else if (dateValues.value[0] && limitDayCount.value <= 0) {
        const beforeStartDate = datesTimestamp < startDateTimestamp;

        return beforeStartDate;
      }
    };

    const setDateRange = () => {
      const startDate = formatTime(dateValues.value[0]);
      const endDate = formatTime(dateValues.value[1]);

      emit('change', [startDate, endDate]);
    };

    return {
      dateValues,
      dateLimit,
      clearEndDate,
      setDateRange
    };
  }
};
